<template>
  <section>
    <!-- Table Top -->
    <b-sidebar
      id="edit-match-pool-sidebar"
      v-model="openSideBar"
      title="Pool Properties"
      bg-variant="white"
      right
      shadow
      backdrop
      aria-controls="edit-match-pool-sidebar"
      :aria-expanded="openSideBar"
      width="70vw"
      @hidden="onHidden"
    >
      <validation-observer
        ref="createPoolObserver"
      >
        <section>
          <b-card>
            <b-form>
              <b-row>
                <b-col sm="6">
                  <validation-provider
                    v-slot="validationContext"
                    ref="poolName"
                    rules="required"
                    name="Pool Name"
                    vid="title"
                  >
                    <b-form-group
                      label="Pool Name"
                      label-for="Pool Name"
                    >
                      <b-form-input
                        id="pool-name"
                        v-model="poolData.title"
                        type="text"
                        placeholder="Pool Name"
                        :state="
                          getValidationState(
                            validationContext
                          )
                        "
                      />
                    </b-form-group>
                    <b-form-invalid-feedback
                      :state="
                        getValidationState(
                          validationContext
                        )
                      "
                    >
                      {{
                        validationContext.errors[0]
                      }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                  <b-form-group
                    label="Search"
                    label-for="Search"
                  >
                    <b-input-group
                      class="mb-2"
                    >
                      <b-input-group-prepend class="search-icon">
                        <feather-icon
                          icon="SearchIcon"
                          size="14"
                        />
                      </b-input-group-prepend>
                      <b-form-input
                        id="`type-search`"
                        v-model="search"
                        type="search"
                        placeholder="Search"
                        class="pool-search"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <validation-provider
                    v-slot="validationContext"
                    ref="matchesPerMentor"
                    rules="required"
                    :name="matchesPerMentorLabel"
                  >
                    <b-form-group
                      :label="matchesPerMentorLabel"
                      :description="matchesPerMentorDescription"
                      label-for="matches-per-mentor"
                    >
                      <v-select
                        id="matches-per-mentor"
                        v-model="matchesPerMentor"
                        :label="matchesPerMentorLabel"
                        :placeholder="matchesPerMentorLabel"
                        :options="numberOfMentorsAllowed"
                      />
                    </b-form-group>
                    <b-form-invalid-feedback
                      :state="
                        getValidationState(
                          validationContext
                        )
                      "
                    >
                      {{
                        validationContext.errors[0]
                      }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
              </b-row>
              <div v-if="isLoading">
                <aom-skeleton-loader />
              </div>
              <b-row>
                <b-col sm="6">
                  <h5 class="mt-50 mb-50">
                    Participants {{ selectedUsersCount }}
                  </h5>
                  <b-row>
                    <b-col sm="12">
                      <b-form-checkbox
                        v-model="isSelectAllUsers"
                        class="mt-1 mb-1"
                        @change="checkAllUsers()"
                      >
                        Select All
                      </b-form-checkbox>
                    </b-col>
                  </b-row>
                  <div v-if="(poolUsers.length === 0 && !isLoading)">
                    <h5 class="text-muted text-center mt-3">
                      No results found.
                    </h5>
                  </div>
                  <b-list-group
                    v-for="(poolUser, index) in poolUsers"
                    :key="index"
                  >
                    <b-list-group-item class="d-flex align-items-center">
                      <b-form-group
                        v-slot="{ ariaDescribedby }"
                        class="mb-0"
                      >
                        <div class="d-flex">
                          <b-form-checkbox-group
                            v-model="selectedUsers"
                            :aria-describedby="ariaDescribedby"
                            name="selected-match"      
                            class="mt-2"
                          >
                            <b-form-checkbox
                              :value="{role_id: poolUser.role_id, user_id: poolUser.user_id}"
                              :disabled="useHasUserReachedMatchLimit(poolUser)"
                            />
                          </b-form-checkbox-group>
                          <div class="left-flex mr-1 ml-1">
                            <b-avatar
                              variant="primary"
                              :text="userInitials(poolUser.user)"
                              :src="userProfileImage(poolUser.user)"
                              size="3rem"
                              class="left-flex"
                            />
                          </div>
                          <div
                            class="right-flex"
                          >
                            <h5 class="mt-50 mb-0">
                              {{ poolUser.user.full_name }}
                              <span
                                v-if="useHasUserReachedMatchLimit(poolUser)"
                                v-b-tooltip.hover.righttop="`User has reached their match limit`"
                                class="disabled-icon ml-2"
                                variant="primary"
                              >
                                <feather-icon
                                  icon="InfoIcon"
                                  size="20"
                                />
                              </span>
                            </h5>
                            <p class="text-muted mb-0">
                              <small>{{ poolUser.user.email }}</small>
                            </p>
                            <p class="mb-0">
                              <small>{{ roleAlias(poolUser.role_id, defaultProgram) }}</small>
                            </p>
                          </div>
                        </div>
                      </b-form-group>
                    </b-list-group-item>
                  </b-list-group>
                </b-col>
                <b-col sm="6">
                  <h5 class="mt-50 mb-50">
                    Groups {{ selectedGroupsCount }}
                  </h5>
                  <b-row>
                    <b-col sm="12">
                      <b-form-checkbox
                        v-model="isSelectAllGroups"
                        class="mt-1 mb-1"
                        @change="checkAllGroups()"
                      >
                        Select All
                      </b-form-checkbox>
                    </b-col>
                  </b-row>
                  <div v-if="(groups.length === 0 && !isLoading)">
                    <h5 class="text-muted text-center mt-3">
                      No results found.
                    </h5>
                  </div>
                  <b-list-group
                    v-for="(group, index) in groups"
                    :key="index"
                  >
                    <b-list-group-item class="d-flex align-items-center">
                      <b-form-group v-slot="{ ariaDescribedby }">
                        <div class="d-flex">
                          <b-form-checkbox-group
                            v-model="selectedGroups"
                            :aria-describedby="ariaDescribedby"
                            name="selected-group"
                            class="mt-2"
                          >
                            <b-form-checkbox :value="{id: group.id}" />
                          </b-form-checkbox-group>
                          <div
                            class="right-flex"
                          >
                            <h5 class="mt-50 mb-0">
                              {{ group.name }}
                            </h5>
                            <p class="text-muted mb-0">
                              <small>{{ groupTypeDisplay[group.type_id] }}</small>
                            </p>
                          </div>
                        </div>
                      </b-form-group>
                    </b-list-group-item>
                  </b-list-group>
                </b-col>
              </b-row>  
            </b-form>
          </b-card>
        </section>
      </validation-observer>
      <template #footer="{ hide }">
        <b-row
          v-if="!isLoading"
          class="ml-1 mr-1 pt-1 border-top"
        >
          <b-col
            sm="12"
            md="4"
            class="mb-1"
          >
            <b-button
              block
              type="reset"
              variant="outline-dark"
              @click="hide"
            >
              Cancel
            </b-button>
          </b-col>
          <b-col
            sm="12"
            md="5"
            class="mb-1"
          >
            <b-button
              type="submit"
              block
              variant="primary"
              :disabled="isDisabled"
              @click="createPool"
            >
              <feather-icon
                v-if="!isUpdating" 
                size="16"
                icon="SaveIcon"
                class="mr-50"
              />
              <b-spinner
                v-if="isUpdating" 
                small
              />
              <span
                v-if="isUpdating"
              > Updating...</span>
              <span v-else>Update Pool</span>
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-sidebar>
  </section>
</template>
        
<script>
import {
  BRow,
  BCol,
  BSidebar,
  BButton,
  BSpinner,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BCard,
  BForm,
  BInputGroup,
  BInputGroupPrepend,
  BListGroupItem,
  BListGroup,
  BAvatar,
  VBTooltip
} from "bootstrap-vue";
        
import Ripple from 'vue-ripple-directive';
import { ValidationObserver, ValidationProvider } from "vee-validate";
import matchesService from '@/services/matchesService';
import { mapGetters } from 'vuex';
import { makeSuccessToast, makeErrorToast } from "@/libs/utils";
import { userRoles, userRolesDisplay, groupTypeDisplay, MatchingPoolClass } from '@models';
import _debounce from "lodash/debounce";
import { getValidationState } from "@/libs/utils";
import vSelect from "vue-select";
import AomSkeletonLoader from "@aom-core/AomSkeletonLoader.vue";
//eslint-disable-next-line
import { required } from "@validations";
import { useNumberOfMentorsAllowed } from './useNumberOfMentorsAllowed';
import { useHasUserReachedMatchLimit } from './useHasUserReachedMatchLimit';
import { roleAlias } from "@/@aom-core/utils/utils";

export default {
  name: 'CreateMatchPoolSideBar',
  components: {
    BRow,
    BCol,
    BSidebar,
    BButton,
    BSpinner,
    BFormCheckbox,
    BFormCheckboxGroup,
    ValidationObserver,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    BFormInvalidFeedback,
    vSelect,
    BCard,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    BListGroupItem,
    BListGroup,
    BAvatar,
    AomSkeletonLoader
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip
  },
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  data(vm) {
    return {
      openSideBar: vm.open,
      isLoading: false,
      isUpdating: false,
      poolData: {},
      poolUsers: [],
      selectedUsers: [],
      groups: [],
      selectedGroups: [],
      search: '',
      userRolesDisplay,
      groupTypeDisplay,
      matchesPerMentor: undefined,
      isSelectAllUsers: false,
      isSelectAllGroups: false,
      numberOfMentorsAllowed: []
    };
  },
  computed: {
    ...mapGetters('programs',['defaultProgram', 'defaultProgramId']),
    isDisabled() {
      return this.selectedUsers.length === 0 && this.selectedGroups.length === 0;
    },
    reciprocalRoleInMatch() {
      return Number(this.role) === Number(userRoles.MENTEE) ? userRoles.MENTOR : userRoles.MENTEE;
    },   
    selectedUsersCount () {
      return Number(this.selectedUsers.length);
    },
    selectedGroupsCount () {
      return Number(this.selectedGroups.length);
    },
    matchesPerMentorLabel() {
      return "Matches per " + roleAlias(userRoles.MENTOR, this.defaultProgram);
    },
    matchesPerMentorDescription() {
      return "Restricts how many "+roleAlias(userRoles.MENTEE, this.defaultProgram).toLowerCase()+"s a "+roleAlias(userRoles.MENTOR, this.defaultProgram).toLowerCase()+" can be paired with in this pool";
    }
  },
  watch: {
    open() {
      this.loadCandidates();
      this.loadPoolGroupCandidates();
      this.openSideBar = this.open;
    },
    openSideBar(n) {
      if(!n) {
        this.$emit('toggleSideBar', n);
      }
    },
    search: _debounce(function(newVal){
      this.loadLists(newVal);
    }, 1000),
    defaultProgram: {
      handler (n) {
        if(n) {
          this.numberOfMentorsAllowed = useNumberOfMentorsAllowed();
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    checkAllUsers() {
      this.selectedUsers = [];
      if(this.isSelectAllUsers) { 
        this.poolUsers.forEach(user => {
          if(!useHasUserReachedMatchLimit(user)) {
            this.selectedUsers.push({user_id: user.user_id, role_id: user.role_id});
          }
        });
      }
    },
    updateCheckAll () {
      this.isSelectAllUsers = this.selectedUsers.length === this.poolUsers.length;
    },
    checkAllGroups() {
      this.selectedGroups = [];
      if(this.isSelectAllGroups) { 
        this.groups.forEach(group => {
          this.selectedGroups.push({id: group.id});
        });
       
      }
    },
    updateCheckAllGroups () {
      this.isSelectAllGroups = this.groups.length === this.selectedGroups.length;
    },
    loadLists(search) {
      this.loadCandidates(search);
      this.loadPoolGroupCandidates(search);
    },
    async loadCandidates(search)  {
      const filter = {
        columnFilters: [],
      };
      if(search) {
        filter.columnFilters.push({field: "keyword",value: search});
      }  
          
      try {
        const programId = this.defaultProgramId;
        this.isLoading = true;
        this.poolUsers = [];
        const response = await matchesService.listPoolUserCandidates(programId, filter);
        const { data } = response;
        this.poolUsers = data.items;
      } catch (e) {
        console.log(e);
        this.$toast(makeErrorToast('Error. Fetching candidates list'));
        this.$log.error(e);
      }finally {
        this.isLoading = false;
      }
    },
    async loadPoolGroupCandidates(search) {
      const filter = {
        columnFilters: [],
      };
      if(search) {
        filter.columnFilters.push({field: "name",value: search});
      }  
          
      try {
        const programId = this.defaultProgramId;
        this.isLoading = true;
        this.groups = [];
        const response = await matchesService.listPoolGroupCandidates(programId, filter);
        const { data } = response;
        this.groups = data.items;
      } catch (e) {
        this.$toast(makeErrorToast('Error. Fetching Pool Groups list'));
        console.log(e);
        this.$log.error(e);
      }finally {
        this.isLoading = false;
      }
    },
    async createPool() {
      const isValid = await this.$refs.createPoolObserver.validate();
      if (!isValid) {
        return;
      }
      try {
        const programId = this.defaultProgramId;
        const pool = new MatchingPoolClass({
          ...this.poolData,
          title: this.poolData.title,
          groups: this.selectedGroups,
          pool_users: this.selectedUsers
        });
        if(this.matchesPerMentor) {
          pool['pool_roles'] = [{role_id: userRoles.MENTOR, match_limit: this.matchesPerMentor }];
        }
        this.isUpdating = true;
        const response = await matchesService.createPool(programId, pool);
        this.$toast(makeSuccessToast('Matching pool updated successfully.'));
        this.$emit('create', response);
        this.loadLists();
        this.openSideBar = false;
      } catch (e) {
        const { status, data } = e.response;
        if (status === 422 && data.errors && data.errors.title) {
          this.$refs.createPoolObserver.setErrors(data.errors);
        } else {
          this.$toast(makeErrorToast(data.message));
          this.$log.error(e);
        }
      } finally {
        this.isUpdating = false;    
      }
    },
    userInitials(user) {
      const firstInt = Array.from(user.first_name)[0];
      const lastInt = Array.from(user.last_name)[0];
      return `${firstInt}${lastInt}`;
    },
    onHidden() {
      this.openSideBar = false;
      this.isLoading = false,
      this.isUpdating = false;
      this.matchedUsers = [];
      this.selectedMatch = undefined;
      this.poolUsers = [];
      this.selectedUsers = [];
      this.groups = [];
      this.selectedGroups = [];
      this.search = '';
      this.matchesPerMentor = undefined;
      this.poolData = {};
      this.$emit('close', true);
    },
    userProfileImage(user) {
      return user.profile_image?.url || '';
    }
  },
  setup() {
    return {
      getValidationState,
      useHasUserReachedMatchLimit,
      roleAlias
    };
  }
};
</script>
        
<style lang="scss">
    $sidebar-header-text-color: #808080;
    .b-sidebar > .b-sidebar-header{
        flex-direction: row-reverse;
        background: #f3f3f3;
        color: $sidebar-header-text-color;
        #edit-match-pool-sidebar___title__ {
            font-size: .8em;
            flex: 2;
        }
    }
    .card {
        box-shadow: none;
    }
    .full-width {
        width: 100%
    }
    .border-top {
        border-top: 1px solid #ccc;
    }
    .list-group-item {
        .form-group {
            flex:2;
            .custom-control-label {
                width: 100%;
            }
        }
    }
    .search-icon {
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        border-right: none;
        margin-right:-5px;
    }
    .pool-search {
        border-left: none;
    }
    .right-flex {
      overflow: auto;
    }
</style>
      